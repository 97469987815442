import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    betaLogo: {
        filter: "drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7))",

    }
})

export const BetaLogo = ({show=true}) => {
    const classes = useStyles()

    if(show) {
        return (
            <div
                className={classes.betaLogo}
            >
                <img src={"https://ems.dimu.org/image/019EBvjs37nME?mediaType=image/svg%2Bxml"} alt={"beta version of app"}/>
            </div>
        )
    } else {
        return null
    }
}