import {With3D} from "../3d/With3D";
import React from "react";
import {createUseStyles} from "react-jss";
import {ModelViewer} from "@ekultur/3d-microfrontend";
import {DURATION_CHANGED, useHeroDispatch} from "./heroContext";

const useStyles = createUseStyles(() => ({
    model3D: {
        cursor: "grab",
        height: "90vh",
        position: "absolute",
        display: "flex",
        right: "0",
        width: "70vw",
        zIndex: 10,
    },
    loadingWrapper: {
        width: "100%",
    },
    model: {
        height: "90vh",
        width: "100%",
    },
}))

export const Hero3dModel = ({model3d}) => {
    const classes = useStyles()
    const heroDispatch = useHeroDispatch()

    const clickHandler = () => {
        heroDispatch({
            type: DURATION_CHANGED,
            duration: 100000000
        })
    }

    if(model3d) {
        return (
            <div className={classes.model3D} onClick={clickHandler}>
                <With3D classname={classes.loadingWrapper}>
                    <div className={classes.model}>
                        <ModelViewer
                            scene={model3d}
                            showGizmo={false}
                            showToolbar={false}
                            enablePan={false}
                            enableZoom={false}
                            onProgressChange={() => {}}
                        />
                    </div>
                </With3D>
            </div>
        )
    } else {
        return null
    }
}