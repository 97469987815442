import {ExperienceLayoutRow} from "../experience/ExperienceLayoutRow";
import {ExperienceCard} from "../experience/ExperienceCard";
import React from "react";
import {ExhibitionCardSizes} from "./helpers";
import {createUseStyles} from "react-jss";
import {EditExperience} from "../experience/EditExperience";
import {EditLayoutRow} from "../experience/EditLayoutRow";
import {useAdminProps} from "../admin/adminContext";
import {useBreakpoints} from "../layout/useBreakpoints";
import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";
import {LargeExperienceRow} from "./LargeExperienceRow";

const useStyles = createUseStyles({
    cardWithSiblings: {
        margin: "0 6px 0 6px",
        width: "calc(100% - 12px)",
        "&:first-child": {
            margin: "0 6px 0 0",
            width: "calc(100% - 6px)",
        },
        "&:last-child": {
            margin: "0 0 0 6px",
            width: "calc(100% - 6px)",
        },

    },
    cardWithoutSiblings: {
        margin: 0
    }
})

export const EditExperiences = ({layout, exhibitionContents, startExhibition}) => {
    const classes = useStyles()
    const {
        mobileView,
        adminMode
    } = useAdminProps()
    const breakpoints = useBreakpoints()
    const matchSmallDown = useMediaQuery(down(breakpoints.small))

    if(!exhibitionContents) {
        return null
    }

    const contentByType = exhibitionContents.reduce((acc, cur) => {
        if(!acc[cur.exhibitionType.type]) {
            acc[cur.exhibitionType.type] = []
        }
        acc[cur.exhibitionType.type] = [...acc[cur.exhibitionType.type], cur]
        return acc;
    }, {});

    const hasSiblings = ({size, type}) => size !== ExhibitionCardSizes.LARGE && 1 < contentByType[type].length

    const experienceAdminComponent = type => {
        if(mobileView || !adminMode) {
            return null
        } else {
            return (
                <EditLayoutRow
                    layoutType={type}
                    layoutsWithContent={layout.filter(l => Object.keys(contentByType).includes(l.type))}
                />
            )
        }
    }

    const layoutiOsExclusion = () => {
        if(/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            return layout.filter(l => l.type !== "3droom")
        } else {
            return layout
        }
    }

    const exhibitionContentsIOsExclusion = () => {
        if(/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            return exhibitionContents.filter(ec => ec.exhibitionType.type !== "3droom")
        } else {
            return exhibitionContents
        }
    }

    if(matchSmallDown) {
        const contentWithLayoutPriority = exhibitionContentsIOsExclusion().map(ec => ({
            ...ec,
            priority: layoutiOsExclusion().find(l => l.type === ec.exhibitionType.type).priority,
            size: layoutiOsExclusion().find(l => l.type === ec.exhibitionType.type).size
        }))
        return (
            contentWithLayoutPriority
                .sort((a, b) => a.priority - b.priority)
                .map((ec) => (
                    <LargeExperienceRow
                        key={`exhibitionRow-${ec.contentId}`}
                        size={ec.size}
                        exhibitionContents={[ec]}
                        startExhibition={startExhibition}
                    />
                ))
        )
    } else {
        return layoutiOsExclusion()
            .sort((a, b) => a.priority - b.priority)
            .map((layoutItem) => (
                <ExperienceLayoutRow
                    key={layoutItem.type}
                    size={layoutItem.size}
                    border={!mobileView && adminMode}
                    AdminMenuComponent={experienceAdminComponent(layoutItem.type)}
                >
                    {contentByType[layoutItem.type] && contentByType[layoutItem.type].map(experience => (
                        <EditExperience
                            key={experience.contentId}
                            contentId={experience.contentId}
                            className={hasSiblings(layoutItem) ? classes.cardWithSiblings : classes.cardWithoutSiblings}
                        >
                            <ExperienceCard
                                showBetaLogo={!adminMode}
                                exhibition={experience}
                                size={layoutItem.size}
                                onClick={startExhibition}
                                siblings={layoutItem.size === ExhibitionCardSizes.LARGE ? 0 : contentByType[layoutItem.type].length - 1}
                            />
                        </EditExperience>
                    ))}
                </ExperienceLayoutRow>
            ))    }
}