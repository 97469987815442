import {Nav} from "./Nav";
import {NavLeftSide} from "./NavLeftSide";
import {NavRightSide} from "./NavRightSide";
import {BackgroundButton} from "./BackgroundButton";
import {Box} from "@mui/material";
import {useAdminProps} from "../admin/adminContext";
import {BetaLogo} from "../logo/BetaLogo";

export const AdminNav = () => {
    const {
        mobileView,
        adminMode
    } = useAdminProps()

    return (
        <Nav>
            <NavLeftSide>
                <Box
                    sx={mobileView || !adminMode ? {display: "none"} : {}}
                >
                    <BackgroundButton />
                </Box>
            </NavLeftSide>
            <NavRightSide>
                <Box id={'vm-beta-stamp-logo'}>
                    <a href={"https://dok.digitaltmuseum.org/virtuelle-opplevelser"} target={"_blank"}>
                        <BetaLogo
                            show={!adminMode}
                        />
                    </a>
                </Box>
            </NavRightSide>
        </Nav>
    )
}