import React from "react";
import {App} from "./app/App";
import reportWebVitals from "./reportWebVitals";
import "./app/i18n";

import "normalize.css";
import "./index.css";
import {Microfrontend} from "./app/Microfrontend";
import {AdminProvider} from "./admin/adminContext";
import {EditExhibition} from "./exhibition/EditExhibition";
import {AdminHeroProvider} from "./admin/adminHeroContext";
import {EditHero} from "./hero/EditHero";
import {HeroProvider} from "./hero/heroContext";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

document.documentElement.lang = "no";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const roots  = {}

window.kitPortal = {
    render: args => {
        const container = document.getElementById(args.containerId)
        const root = createRoot(container)
        root.render(
            <App />
        )
    },
    renderEditExhibitionPage: args => {
        const container = document.getElementById(args.containerId)
        if(!roots[args.containerId]) {
            roots[args.containerId] = createRoot(container)
        }
        console.debug({args})
        roots[args.containerId].render(
                <Microfrontend>
                    <AdminProvider
                        onChange={args.onChange}
                        onMediaSelect={args.onMediaSelect}
                        onExperienceSelect={args.onExperienceSelect}
                        data={args.exhibition}
                        onExperienceRemove={args.onExperienceRemove}
                        mobileView={args.mobileView}
                        errors={args.errors}
                    >
                        <EditExhibition />
                    </AdminProvider>
                </Microfrontend>
        )
    },
    renderExhibitionPage: args => {
        Sentry.init({
            dsn: "https://c83809897e9741c0beb91d6fee5f4168@o1289125.ingest.sentry.io/4504237308444672",
            integrations: [new BrowserTracing()],
            tracesSampleRate: 0.2,
        })
        const container = document.getElementById(args.containerId)
        if(!roots[args.containerId]) {
            roots[args.containerId] = createRoot(container)
        }
        console.debug({args})
        roots[args.containerId].render(
            <Microfrontend
                fetchEkulturData={false}
            >
                <AdminProvider
                    onChange={() => null}
                    onMediaSelect={() => null}
                    onExperienceSelect={() => null}
                    data={args.exhibition}
                    onExperienceRemove={() => null}
                    mobileView={false}
                    errors={[]}
                    adminMode={false}
                    museum={args.museum}
                >
                    <EditExhibition />
                </AdminProvider>
            </Microfrontend>
        )
    },
    renderEditHero: args => {
        const container = document.getElementById(args.containerId)
        if(!roots[args.containerId]) {
            roots[args.containerId] = createRoot(container)
        }
        roots[args.containerId].render(
            <Microfrontend>
                <AdminHeroProvider
                    onChange={args.onChange}
                    onMediaSelect={args.onMediaSelect}
                    onEffectSelect={args.onEffectSelect}
                    onTextColorSelect={args.onTextColorSelect}
                    data={args.hero}
                    mobileView={args.mobileView}
                >
                    <HeroProvider>
                        <EditHero />
                    </HeroProvider>
                </AdminHeroProvider>
            </Microfrontend>
        )
    },
    unmount: containerId => {
        if(roots[containerId]) {
            roots[containerId].unmount()
            roots[containerId] = null
            console.debug('unmount!!!')
        }
    }
}